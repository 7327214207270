/*=========================================*\
    Typography
    	| All typographical settings
    	| grouped here for clean storage
    	| of @font-face & continuity
\*=========================================*/
    body {
        font-size: 100%;
        -webkit-text-size-adjust: 100%;
        font-weight: normal;
        line-height: 1.5;
    }
    
    /*=========================================*\
        @font-face
        	| All font-face declarations
        	| placed here (out of the way)
    \*=========================================*/
    @font-face {
	  font-family: 'fontello';
	  src: url("../fonts/fontello.eot?33482461");
	  src: url("../fonts/fontello.eot?33482461#iefix") format('embedded-opentype'), url("../fonts/fontello.woff?33482461") format('woff'), url("../fonts/fontello.ttf?33482461") format('truetype'), url("../fonts/fontello.svg?33482461#fontello") format('svg');
	  font-weight: normal;
	  font-style: normal;
	}
	
	.icon-home:before { content: '\2302'; } /* '⌂' */
	.icon-menu:before { content: '\2630'; } /* '☰' */
	.icon-mail:before { content: '\2709'; } /* '✉' */
	.icon-down-open:before { content: '\e75c'; } /* '' */
	.icon-right-open:before { content: '\e75e'; } /* '' */
	.icon-twitter:before { content: '\f309'; } /* '' */
	.icon-facebook:before { content: '\f30c'; } /* '' */
	.icon-linkedin:before { content: '\f318'; } /* '' */
	.icon-search:before { content: '🔍'; } /* '\1f50d' */

    /*=========================================*\
        Font Stacks
        	| Use to create theme-specific
        	| mixin for all fonts used
    \*=========================================*/
    @mixin font-stack($stack){
	    @if $stack == Proxima {
		    font-family: "proxima-nova", sans-serif;
	    }
    }
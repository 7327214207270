.side-filter {
  margin: 20px 0;
  overflow: hidden;

  &__title {
    font-size: 16px;
    text-transform: uppercase;
  }

  li {
    margin-bottom: 5px;
  }

  &__child {
    margin-left: 20px;
  }
}
/*============================================*\
   Content Managed Code
    	| All styling for code managed
    	| through the WordPress admin,
    	| all nested within .cms-area
\*============================================*/
.cms-area {
}

.cms-area h1 {  }
.cms-area h2 {  }
.cms-area h3 {  }
.cms-area h4 {  }
.cms-area h5 {  }
.cms-area h6 {  }

.cms-area p,
.cms-area ul,
.cms-area ol,
.cms-area dl {
	font-size: 14px;
	line-height: 1.5;
	margin-bottom: 1em;
}

.cms-area.strapline p {
	font-size: 16px;
}
h2 {
  font-size : 24px;
  margin-bottom: 20px;
   }
h3 {
    font-size : 18px; 
   }
.italic {
  font-style : italic;    
}
p.strapline {
	font-size: 15px;
}

.cms-area blockquote p {
	font-size: 24px;
	color: $black;
	border-top: 1px solid lighten($darkGrey, 5%);
	border-bottom: 1px solid lighten($darkGrey, 5%);
	padding: .4em 0 .4em .2em;
  margin: 20px 0;
	font-weight: normal;
	line-height: 1.3;
}

.cms-area {
	img {
		display: block;
		margin-bottom: 1em;
		
		&.alignleft {
			float: left;
			margin-right: 15px;
		}
		
		&.alignright {
			float: right;
			margin-left: 15px;
		}
		
		&.aligncenter {
			margin: 0 auto 1em;
		}
	}
}
.clear-both:after {
    clear : both;
    display : block;
    height : 0;
    content : '';
}
a:focus {
    outline : none;
}
.facebook {
     float : left;
     margin-right : 1em;
  margin-top: -4px;
}

.grid-1-2-4  {

        width:100%;

        @include breakpoint(small-tablet){

         position : relative;
         width:105%;
         margin-left : -5%; 

         }

         > div {

          width:100%;

          @include breakpoint(small-tablet){

               float : left; 
               width : 45%;
               margin-left : 5%; 
           }         

           
        }
}
.grid-1-2-4 {

        @include breakpoint(laptop){

         position : relative;
         width : 102%;
         margin-left : -2%; 

         }
          > div.mult-1 { 

           @include breakpoint(laptop){

                width:30%;
                margin-left:1.5%;

            }
        }

         > div.mult-2 { 

           @include breakpoint(laptop){

                width:17.5%;
                margin-left:.8%;

            }
        }
}
.grid-1-2-3 {

       /* @include breakpoint(netbook){

         position:relative;
         width:103.3%;
         margin-left:-3.3%; 

         }

         > li {

           @include breakpoint(netbook){

                width:30%;
                margin-left:3.3%;

            }
        }*/
}

.top-spacing {
        margin-top : 2em;
}
.top-link {
        display : block;
        margin-top : 2.3em;
        text-align : center;
}


.grid-1-2-3 {

        width:100%;

        @include breakpoint(small-tablet){

         position : relative;
         width:105%;
         margin-left : -5%; 

         }

         > div {

          width:100%;

          @include breakpoint(small-tablet){

               float : left; 
               width:45%;
               margin-left:5%; 
           }         

           
        }
}
.grid-1-2-3 {

        @include breakpoint(netbook){

         position:relative;
         width:103.3%;
         margin-left:-2.3%; 

         }
        
         > div {

           @include breakpoint(netbook){

                width:30%;
                margin-left:2.3%;

            }
        }
}

.minor-item {
    width : 100%;
    @include breakpoint(netbook){
        width : 26%;
    }
}
.half {
    width : 100%;
    @include breakpoint(netbook){
        width : 50%;
    }
}
.main-item {
    width : 100%;
    @include breakpoint(tablet-portrait){
        width : 85%;
    }
    
}

.inner-top {
  padding : 1em 0;   
}
.flush-img img {
  min-width : 100%;
}
.match-height {
   min-height : 332px;   
}
.m-height {
  min-height : 360px;   
} 
.news-height {
        min-height : 264px;
} 
.about-list {
    ul {
        list-style : disc; 
        margin-left : 1em;
    }
}
 
.news-main {
    margin-bottom: 20px;
    border-bottom: 1px solid #CCC;
    padding-bottom: 20px;

    h2 {
        margin-bottom: 0;
    }

    .date {
        font-size: 14px;
        margin-bottom: 10px;
        display: block;
    }

    .entry {
        margin-bottom: 10px;
    }

    .postmetadata {
        font-size: 14px;
        font-weight: 700;
    }
}
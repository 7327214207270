/*=========================================*\
    Header
        | All code required for the
        | header section of the site,
        | including navigation
\*=========================================*/
.myflash {
  width: 100%;
  overflow: hidden;
}

.video-swf,
.flash-embed {

  // @include breakpoint(tablet-portrait){
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -500;
  //}
}


.head-upper {
  position: relative;
  background-image: url("../images/head-bg-small.jpg");

  @include breakpoint(small-tablet) {
    padding: 1.5em 0 0;
    position: relative;
  }

  @include breakpoint(tablet-portrait) {
    padding: 0;
    min-height: 265px;
    background-image: url('../images/head-bg-small.jpg');
    background-repeat: no-repeat;
    background-position: 75% 0%;
    padding-bottom: 2em;

    &.head-upper-1 {
      background-image: url('../images/head-bg-small-2.jpg');
    }
  }

  @include breakpoint(desktop) {
    padding: 0;
    background-size: cover;
    background-position: top center;
    padding-bottom: 2em;
  }

  .home & {

    @include linear-gradient(lighten(#A5ABAD, 35%), lighten(#A5ABAD, 22.5%));
    @include box-sizing(border-box);
    padding: 1em 0 0;

    @include breakpoint(small-tablet) {
      padding: 1.5em 0 0;
      position: relative;
    }

    @include breakpoint(tablet-portrait) {
      padding: 0;
      min-height: 698px;
      min-height: 500px;
      background-image: url('../images/head-bg.jpg');
      background-position: 70% 0%;

      &.head-upper-1 {
        background-image: url('../images/head-bg-2.jpg');
      }
    }

    @include breakpoint(tablet-landscape) {
      min-height: 600px;
    }

    @include breakpoint(desktop) {
      background-size: cover;
      background-position: top center;
      min-height: 698px;
    }
  }

}

.head-container {
  width: 100%;
  max-width: 100%;
  margin: 0;

  .head-major {
    max-width: 1140px;
    width: 90%;
    margin: 0 auto;
  }

  @include breakpoint(tablet-portrait) {
    max-width: 1140px;
    width: 90%;
    margin: 0 auto;
    position: relative;

    .head-major {
      max-width: 200px;
      float: left;
      margin: 0;
    }
  }
}

.site-title {
  display: block;
  max-width: 220px;
  float: left;
  padding-top: 1em;

  @media only screen and (max-width: 1023px) {
    padding-top: 20px;
    margin-bottom: 20px;
  }
}

.nav-prompt {
  line-height: 1;
  font-size: 30px;
  float: right;
  position: absolute;
  top: 20px;
  right: 5%;

  @media only screen and (min-width: 768px) {
    right: 0;
    right: 0;
  }

  @media only screen and (min-width: 1024px) {
    display: none;
  }

  li {
    margin-top: .2em;

    &:first-child {
      margin-top: 0;
    }
  }

  span {
    display: none;
  }

  a {
    @include inline-block();
    padding: .2em .3em;
    width: 43px;
    text-align: center;

    @include box-sizing(border-box);

    &:hover {
      text-decoration: none;
    }
  }

  .menu a {
    background-color: $black;
    color: $white;
    border-radius: 3px;

    &:before {
      @include icon('fontello', '\2630');
    }
  }

  .search a {
    background-color: $sunflower;
    color: $black;
    border-radius: 3px;

    &:before {
      content: '';
      background: url(../images/search.png) no-repeat;
      width: 25px;
      height: 25px;
      display: block;

      @media only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min-device-pixel-ratio: 2) {
        background-image: url(../images/search@2x.png);
        background-size: 25px 25px;
      }
    }
  }

}


.head-nav {
  float: right;
  margin-top: 60px;
  display: none;

  @media only screen and (min-width: 1024px) {
    display: block;
  }

  @media only screen and (max-width: 1023px) {
    background: black;
    width: 100%;
    margin-top: 0;
    text-align: center;
    position: absolute;
    z-index: 100;
    top: 120px;
  }

  &.open {
    display: block;
  }

  ul {
    display: inline-block;

    @media only screen and (max-width: 1023px) {
      display: block;
    }

    li {
      display: block;
      float: left;
      position: relative;
      padding: 0 12px;

      @media only screen and (max-width: 1023px) {
        display: block;
        float: none;
        padding: 0;
        margin-right: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      &#menu-item-8, &#menu-item-1327 {
        a:before {
          @include icon('fontello', '\2302');
        }
      }

      a {
        display: inline-block;
        color: white;
        font-weight: 700;
        text-decoration: none;
        border-bottom: 2px solid transparent;
        padding-bottom: 3px;
        transition: 0.3s;

        @media only screen and (max-width: 1023px) {
          display: block;
          padding: 10px;
          border-bottom: 2px solid rgba(255, 255, 255, 0.1);
        }

        &:hover,
        &:focus,
        &:active {
          border-bottom: 2px solid $sunflower;
        }

        span {
          display: none;
        }
      }

      ul {
        visibility: hidden;
        position: absolute;
        transition: all 0.5s ease;
        left: 0;
        display: none;
        padding-top: 8px;

        &:hover {
          visibility: visible;
          opacity: 1;
          display: block;

        }

        li {
          clear: both;
          width: 100%;
        }
      }

      &:hover {
        cursor: pointer;

        & > ul {
          visibility: visible;
          opacity: 1;
          display: block;
        }
      }
    }
  }

  //font-weight: bold;
  //font-size: 18px;
  //background-color: $black;
  //display: block;
  //
  //@media only screen and (max-width: 1023px) {
  //    display: none;
  //}
  //
  //&.open {
  //    display: block;
  //}
  //
  //ul {
  //    display: block;
  //    max-width: 80%;
  //    margin: 0 auto;
  //}
  //
  //li {
  //    border-top: 1px solid lighten($black, 10%);
  //
  //    &:first-child {
  //        padding-top: 0;
  //    }
  //}
  //
  //a {
  //    display: block;
  //    text-align: center;
  //    padding: .6em .3em;
  //    color: $white;
  //}
  //
  //.current-menu-item a,
  //a:hover {
  //    text-decoration: none;
  //    color: $sunflower;
  //}

  //@include breakpoint(small-tablet){
  //    display: block;
  //    background-color: $black;
  //    margin-top: 1.5em;
  //    font-size: 16px;
  //
  //    .menu-item-8 {
  //        display: none;
  //    }
  //
  //    a {
  //        padding: .3em;
  //    }
  //
  //    ul {
  //        max-width: 1140px;
  //        width: 90%;
  //        margin: 0 auto;
  //    }
  //
  //    li {
  //        width: 15%;
  //        float: left;
  //        border: 0;
  //
  //    }
  //}
  //
  //@include breakpoint(medium-tablet) {
  //    li {
  //        margin-left : 0px;
  //        width : auto ;
  //    }
  //}
  //
  //@include breakpoint(tablet-portrait){
  //    background-color: transparent;
  //    float: right;
  //    display: inline-block;
  //
  //    ul {
  //        width: 100%;
  //        max-width: 100%;
  //        margin: 0;
  //    }
  //
  //    li {
  //        width: auto;
  //        margin-left: 15px;
  //    }
  //
  //    a {
  //        display: block;
  //        text-align: center;
  //        padding: 0;
  //        color: $white;
  //        line-height: 1.3;
  //    }
  //
  //    .current-menu-item a,
  //    a:hover {
  //        background-color: transparent;
  //        color: $white;
  //        border-bottom: 2px solid $sunflower;
  //    }
  //}
  //
  //@include breakpoint(netbook){
  //    li {
  //        margin-left: 30px;
  //    }
  //}
  //
  //@include breakpoint(tablet-landscape){
  //    margin-top: 1.9em;
  //    font-size: 16px;
  //
  //    .menu-item-8 {
  //        display: block;
  //
  //        a:before {
  //            @include icon('fontello', '\2302');
  //        }
  //
  //        span {
  //            display: none;
  //        }
  //    }
  //
  //    li {
  //        margin-left: 30px;
  //    }
  //}
  //
  //@include breakpoint(desktop){
  //    margin-top: 3.6em;
  //
  //    li {
  //        margin-left: 30px;
  //    }
  //}
}

.ie7 .head-nav {
  width: 745px;
}

.ie7 {
  .head-nav .menu-item-8 span {
    display: block;
  }

  .major-item .latest-vacancies .split-block {
    padding: 15px;
    width: 300px;

    @include breakpoint(tablet-landscape) {
      width: 250px;
    }

    @include breakpoint(desktop) {
      width: 300px;
    }
  }

  .major-item .latest-news .split-block {
    padding: 15px;
    width: 300px;

    @include breakpoint(tablet-landscape) {
      width: 250px;
    }

    @include breakpoint(desktop) {
      width: 300px;
    }
  }

  .head .telephone li {
    font-size: 17px;
  }

  .latest-vacancies .vacancy-item .cms-area {
    height: 200px;
  }

  .latest-news .cms-area {
    height: 200px;
  }

  .major-item .latest-vacancies .split-right {
    @include breakpoint(laptop) {
      float: none;
    }

    @include breakpoint(desktop) {
      float: right;
    }
  }

  .major-item .latest-vacancies .split-left {
    @include breakpoint(laptop) {
      float: none;
    }

    @include breakpoint(desktop) {
      float: left;
    }
  }
}

// manually adding active state to menu
@include breakpoint(tablet-portrait) {
  .single-vacancies .head-nav .menu-item-22 a {
    background-color: transparent;
    color: $white;
    border-bottom: 2px solid $sunflower;
  }
}

.head .telephone {
  //margin: 1em 0;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 0;

  @media only screen and (max-width: 1023px) {
    right: 10%;
  }

  @media only screen and (max-width: 767px) {
    right: 20%;
  }

  li {
    font-size: 20px;

    @include breakpoint(small-tablet) {
      float: left;
      font-size: 14px;

      &:last-child {
        margin-left: .5em;
      }
    }

    @include breakpoint(medium-tablet) {
      font-size: 20px;
    }

    @include breakpoint(tablet-portrait) {
      //font-size : 20px;   
    }

    a {
      color: $black;
      top: -3em;
      right: 0;
      font-size: 16px;
      background-color: rgba($white, .2);
      padding: .4em .8em;
      @include border-radius(0 0 5px 5px);

      @media only screen and (max-width: 767px) {
        background-color: rgba($black, .2);
      }
    }
  }

  dt,
  dd {
    //@include inline-block();
  }

  abbr {
    font-weight: bold;
    color: $mustard;
  }

  @include breakpoint(small-tablet) {
    //	position: absolute;
    //top: 1.5em;
    //right: 5%;
    float: right;
  }

  @include breakpoint(tablet-portrait) {

    abbr {
      color: $white;
    }
  }
}

.massive-title {
  display: none;

  @include breakpoint(tablet-portrait) {
    display: block;

    color: $white;
    text-align: center;

    line-height: 1.2;
    text-transform: uppercase;
    @include text-shadow(0 1px 3px rgba($black, 1));

    font-size: 50px;
    margin: 1.25em auto 1em;
    max-width: 600px;
  }

  @include breakpoint(tablet-landscape) {
    font-size: 60px;
    margin-top: 2em;
    max-width: 700px;
  }

  @include breakpoint(desktop) {
    margin-bottom: 2.3em;
  }

}
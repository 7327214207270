h1 {
  font-size: 26px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

h4 {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 15px;
}

dd {
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
}
.news-single {

    h1 {
        font-size: 30px;
    }

    .date {
        margin-bottom: 20px;
        display: block;
        font-size: 14px;
    }
}
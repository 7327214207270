// CSS3 Helpers
	// A collection of mixins to help with
	// CSS3 vendor compatibility

// Border Radius
@mixin border-radius($values) {
	-webkit-border-radius: $values;
	-moz-border-radius: $values;
	border-radius: $values;
}

// Shadows	
	// Box
	@mixin box-shadow($values) {
		-webkit-box-shadow: $values;
		-moz-box-shadow: $values;
		box-shadow: $values;
	}
	
	// Text
	@mixin text-shadow($values) {
		text-shadow: $values;
	}
	
	@mixin rgba-bg ($hex, $alpha) {     
  background-color: $hex;
  background-color: rgba($hex, $alpha);
}

// Box Sizing
@mixin box-sizing($type) {
	-webkit-box-sizing: $type;
	-moz-box-sizing: $type;
	box-sizing: $type;
}

// Inline Block
@mixin inline-block() {
	display: -moz-inline-stack;
	display: inline-block;
	vertical-align: middle;
	
	.lte-7 & {
		vertical-align: auto;
		zoom: 1;
		display: inline;
	}
}

// Linear Gradient
@mixin linear-gradient($start, $finish) {
	background: $start;
	background: -webkit-gradient(linear, 0 0, 0 0%, from($start) to($finish));
	background: -webkit-linear-gradient(top, $start 0%, $finish);
	background: -moz-linear-gradient(top, $start 0%, $finish);
	background: -o-linear-gradient(top, $start 0%, $finish);
	background: -ms-linear-gradient(top, $start 0%, $finish);
	background: linear-gradient(top, $start 0%, $finish);
	//filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$start', endColorstr='$finish');
}

// Transforms
@mixin transform ($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
	      	transform: $transforms;
}
	// Rotate
	@mixin rotate ($deg) {
      @include transform(rotate(#{$deg}deg));
    }

// Opacity
	// Specific
	@mixin opacity($amount){
		opacity: $amount;
		filter: alpha(opacity=$amount);
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="$amount")";
	}

	// Transparent
	@mixin transparent(){
		opacity: 0;
		filter: alpha(opacity=0);
		-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	}

	// Opaque
	@mixin opaque(){
		opacity: 1;
		filter: alpha(opacity=100);
		-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	}

// Columns
@mixin columns($col_num, $col_gap){
	-moz-column-count: $col_num;
	-moz-column-gap: $col_gap;
	-webkit-column-count: $col_num;
	-webkit-column-gap: $col_gap;
	column-count: $col_num;
	column-gap: $col_gap;
}
/*=========================================*\
    Page
    	| All code required for the
    	| content area of theme's pages
\*=========================================*/
	
	/*====================================*\
    	Vacancies
    \*====================================*/
    .filter {
    	
    	.submit {
	    	margin-bottom: 0;
    	}
    
	    .main-label {
		    font-size: 18px;
		    text-transform: uppercase;
		    font-weight: bold;
		}
		
		&-inner {
			button {
				max-width: 100%;
			}
		}
    }
	

	/*====================================*\
    	Area Profiles
    \*====================================*/
    .area-list {
	    margin-top: 3em;
    }
    
    .area-item {
    	a {
	    	display: block;
    	}
    	
    	.read-more {
	    	font-size: 12px;
    	}
    
    	@include breakpoint(netbook){
    		&.box-item.flush-img .lower {
	    		padding: .25em 1em .75em;
    		}
    		
	    	.cms-area p {
		    	font-size: 13px;
	    	}
    	}
    	
    	@include breakpoint(desktop){
    		&.box-item.flush-img .lower {
	    		padding: .66em 1.5em .66em;
    		}
    		
	    	.cms-area p {
		    	font-size: 14px;
	    	}
    	}
    } 

	/*====================================*\
    	Meet the Team
    \*====================================*/
    .staff-list {
	    margin-top: 3em;
    }
    
    .staff-member {
	    text-align: center;
	    margin-bottom: 3em;
	    
	    .name {
		    margin-top: 1em;
			margin-bottom: .5em;
			font-size: 16px;
	    }
	    
	    .description {
		    max-width: 300px;
		    margin: 0 auto;
	    }
	    
	    .profile-pic {
		    @include border-radius(50%);
		    @include box-shadow(0 1px 3px rgba($black, .3));
		    display: block;
		    margin: 0 auto;
		    -webkit-filter: grayscale(100%);
		    @include opacity(.9);
	    }
	    
	    dl div {
		    margin-bottom: .33em;
	    }
    }
    
    .email,
    .linkedin {
	    font-size: 14px;
    	font-weight: bold;
    }
    
    .email {
	    color: $dijon;
	    
	    &:before {
		    @include icon('fontello', '\2709');
		    line-height: 1;
		    margin-right: 5px;
		    font-weight: 100;
		    width: 20px;
		    @include inline-block();
		    text-align: center;
	    }
	    
	    &:hover {
	    	color: darken($dijon, 10%);
	    	text-decoration: none;
    	}
    }
    
    .linkedin {
    	color: #3399cc;
    	
	    &:before {
		    @include icon('fontello', '\f318');
		    background-color: #3399cc;
		    color: $white;
		    padding: .3em;
		    line-height: 1;
		    margin-right: 5px;
		    font-weight: 100;
	    }
	    
	    &:hover {
	    	color: darken(#3399cc, 10%);
	    	text-decoration: none;
	    	
	    	&:before {
		    	background-color: darken(#3399cc, 5%);
	    	}
    	}
    }
	

	/*====================================*\
    	Contact
    \*====================================*/
    .vCard {
    	font-size: 14px;
    	
    	a,
    	.org {
	    	color: $dijon;
    	}
    	
    	a {
    		text-decoration: underline;
	    	&:hover {
		    	color: darken($dijon, 10%);
	    	}
	    }
    }
    
    .contact-details {
    	dl {
	    	margin-top: 1.5em;
    	}
    	
    	dt,
    	dd {
	    	@include inline-block();
    	}
    	
    	dt {
	    	width: 45px;
    	}
    
    	.adr {
		    span {
			    display: block;
			    
			    span {
				   display: inline;
				   
				   &:first-child:after {
					   content:', ';
				   }
			    }
		    }
	    }
	}
    
    .map-canvas {
    	margin-top: 2em;
	    height: 400px;
	    
	    img {
		    max-width: none;
	    }
    }
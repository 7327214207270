.sector-slider {
  margin-bottom: 40px;

  .slick-slide {

    div {
      display: block!important;
      width: 100%;
    }
  }

  a {
    color: $sunflower;
    background: $black;
    width: 100%;
    height: 200px;
    font-size: 26px;
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    text-align: center;
    transition: 0.3s;

    &:hover, &:active, &:focus {
      background: $dijon;
      text-decoration: none;
      color: $white;
    }
  }

  &--light {

    .slick-dots {
      li {

        button {
          background: $grey;

        }

        &.slick-active {

          button {
            background: $dijon;
          }
        }
      }
    }
  }
}
.landing-page {
    &__image {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: center center;
        z-index: 1;
    }

    &__logo {
        width: 400px;
        margin: 0 auto 25px;

        @media only screen and (max-width: 767px) {
            width: 70%;
            display: block;
        }
    }

    &__content {
        margin: 0 auto;
        width: 500px;
        display: block;
        text-align: center;
        padding-top: 150px;
        position: relative;
        z-index: 2;

        @media only screen and (max-width: 767px) {
            width: 90%;
        }
    }

    h1 {
        font-weight: 300;
        font-size: 26px;
        margin-bottom: 25px;
    }

    h2 {
        font-size: 16px;
        margin-bottom: 25px;
    }

    &__language {
        display: inline-block;

        li {
            display: inline-block;
            float: left;

            @media only screen and (max-width: 767px) {
                width: 100%;
                display: block;
            }

            a {
                background: $sunflower;
                color: $black;
                border-radius: 5px;
                padding: 10px 25px;
                font-size: 18px;
                min-width: 170px;
                display: inline-block;
                margin: 0 10px;
                font-weight: 700;
                box-sizing: border-box;

                @media only screen and (max-width: 767px) {
                    width: 100%;
                    display: block;
                    margin: 0 0 10px;
                }
            }
        }
    }
}
/*=========================================*\
    Post
    	| Code required for posts,
    	| including cat / tax listing
    	| and singular
\*=========================================*/

	/*=========================================*\
    	Listing
    		| Generic styling for post in
    		| a list
    \*=========================================*/

	/*=========================================*\
    	Category / Tag / Taxonomy
    		| Specific code for
    		| category / tag / taxonomy
    \*=========================================*/
    
    /*=========================================*\
    	Singular
    		| Detail page of post
    \*=========================================*/
    .area-head {
    	padding-bottom: 1em;
    	margin-bottom: 1em;
    	border-bottom: 1px solid $darkGrey;
	    
	    .title {
		    color: $dijon;
		    font-size: 18px;
		    text-align: center;
	    }
	    
	    .btn {
		    margin: .75em auto 0;
		    display: block;
	    }
	    
	    @include breakpoint(mobile-landscape){
		    .title {
			    float: left;
			    text-align: left;
			    margin-top: .4em;
		    }
		    
		    .btn {
			    float: right;
			    margin-top: 0;
		    }
	    }
    }
    
    .latest-vacancies.area-attached {
    	margin-top: 3em;
    	
		.main-title {
		    display: block;
		    font-size: 16px;
		    text-transform: uppercase;
		    margin-bottom: .66em;
	    }
    }
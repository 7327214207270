/*=========================================*\
    Normalise
    	| Code to set some basic styling
\*=========================================*/

	/*=========================================*\
    	Non Semantic
    \*=========================================*/
	.ir {
		display: block;
		border: 0;
		text-indent: -999em;
		overflow: hidden;
		background-color: transparent;
		background-repeat: no-repeat;
		text-align: left;
		direction: ltr;
	}
	
	.ir br {
		display: none;
	}
	
	.hidden {
		display: none !important;
		visibility: hidden;
	}
	
	.visuallyhidden {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
	
	.visuallyhidden.focusable:active,
	.visuallyhidden.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
	
	.invisible {
		visibility: hidden;
	}

    /*=========================================*\
    	Clearfix
    \*=========================================*/
    .cf:before,
    .cf:after {
    	content: "";
    	display: table;
    }
    
    .cf:after {
    	clear: both;
    }
    
    .cf {
    	zoom: 1;
    }
    
    /*=========================================*\
    	Global Normalisation
    \*=========================================*/
	html{
		overflow-y:scroll;
		-webkit-font-smoothing: antialiased;
	}
	
	textarea {
		resize: none;
	}
	
	strong {
		font-weight: bold;
	}
	
	em {
		font-style:italic;
	}
	
	img {
		max-width: 100%;
	}
	
	a img {
		border: none;
	}
	
	a {
		color: $mustard;
		text-decoration: none;
		
		&:hover {
			color: $dijon;
			text-decoration: underline;
		}
	}
/*=========================================*\
    Pagination
\*=========================================*/
.pagination {
    width: 100%;
    display: block;
    margin: 2em 0;

    &:after {
        display: table;
        content: '';
        float: none;
        clear: both;
    }
}

.vacancies-archive .pagination{
	//position:absolute;
//	top:-25px;
//	right:0;
}

.pagination .title{
	display:none;
}


.pagination ol {
    float: right;
    font-size: 1.4em;
    list-style: none;
}

.pagination a {
    cursor: pointer;
}

.pagination li {
    float: left;
    margin-right: 6px;
    display:block;
    @include border-radius(3px);
    background-color:$mustard;
    width:33px;
    text-align:center;
}


.pagination li .title {
    margin-right: 12px;
    font-weight: bold;
}

.pagination li .page,
.pagination li a {
    color:$dark;
    text-decoration: none;
    font-weight:bold;
    font-size:.7em;
    display: block;
    padding: 6px 5px;
}


.pagination li.current{
	background-color:$dark;
	.page{
	color:$mustard;
	}
}

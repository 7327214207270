/*=========================================*\
    Footer
    	| All code required for the
    	| footer section of the site,
    	| including foot navigation
\*=========================================*/

.foot {
	background-color: $white;
	padding: 2em 0;
}

.copy {
	display: block;
	text-align: center;
	font-size: 12px;
	float: left;
	width: 100%;
	
	@include breakpoint(mobile-landscape){
		font-size: 14px;
	}
	
	@include breakpoint(netbook){
		text-align: left;
	}
}

.footer-item,
.social-links {
	
	.title {
		color: $dijon;
		font-size: 18px;
	}
}

.social-links {
	margin-bottom: 2em;
	
	.title {
		margin-bottom: 15px;
	}
	
	.social-icons {
		display: inline-block;
	}
}

.footer-item {
	margin-bottom: 2em;
	text-align: center;
	width: 47.5%;
	margin-right: 5%;
	float: left;
	
	&.mult-2 {
		margin-right: 0;
	}
	
	@include breakpoint(small-tablet){
		width: 23.5%;
		margin-right: 2%;
		
		&.mult-2 {
			margin-right: 2%;
		}
		
		&.mult-4 {
			margin-right: 0;
		}
	}

	h3 {
		font-size: 18px;
		margin-bottom: 15px;
	}
	
	a {
		color: $black;
		font-size: 14px;
	}

	p {
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 15px;
	}
}

.foot-nav {
	display: flex;
	width: 75%;
	float: left;
	flex-wrap: wrap;

	@media only screen and (max-width: 1023px) {
		width: 100%;
	}

	@media only screen and (max-width: 639px) {
		flex-direction: column;
	}

	.footer-item {
		text-align: left;
		flex: 1;

		@media only screen and (max-width: 639px) {
			text-align: center;
			width: 100%;
		}
	}

	img {
		width: 75%;
	}
}
//
//@include breakpoint(netbook){
//	.foot-nav {
//		float: left;
//		width: 75%;
//	}
//
//	.social-links {
//		float: right;
//		width: 22.5%;
//
//		.title {
//			text-align: left;
//		}
//
//		.social-icons {
//			margin: 0;
//		}
//	}
//
//	.footer-item {
//		text-align: left;
//	}
//}
//
//@include breakpoint(tablet-landscape){
//	.foot-nav {
//		width: 82.5%;
//	}
//
//	.social-links {
//		width: 17%;
//	}
//}
//
//@include breakpoint(desktop){
//	.foot-nav {
//		width: 84.5%;
//	}
//
//	.social-links {
//		width: 13%;
//	}
//}

.social-icons {
	li {
		float: left;
		margin-left: 10px;
		
		&:first-child {
			margin-left: 0;
		}
	}

	a {
		display: block;
		padding: .3em;
		@include box-sizing(border-box);
		line-height: 1.1;
		font-size: 24px;
		width: 40px;
		height: 40px;
		text-align: center;
		color: $white;
		background-color: lighten($black, 20%);
		@include border-radius(4px);
		
		&:hover {
			text-decoration: none;
		}
	}
	
	.si_facebook a {
	
		&:hover {
			background-color: #3a579a;
		}
		
		&:before {
			@include icon('fontello', '\f30c');
		}
	}
	
	.si_twitter a {
		&:hover {
			background-color: #00aced;
		}
		
		&:before {
			@include icon('fontello', '\f309');
		}
	}
	
	.si_linkedin a {
		&:hover {
			background-color: #3399cc;
		}
		
		&:before {
			@include icon('fontello', '\f318');
		}
	}
	
	span {
		display: none;
	}
}
/*=========================================*\
    Post
    	| Code required for posts,
    	| including cat / tax listing
    	| and singular
\*=========================================*/

/*=========================================*\
    	Listing
    		| Generic styling for post in
    		| a list
    \*=========================================*/

/*=========================================*\
    	Category / Tag / Taxonomy
    		| Specific code for
    		| category / tag / taxonomy
    \*=========================================*/

/*=========================================*\
    	Singular
    		| Detail page of post
    \*=========================================*/
.vacancy-section {

    margin-bottom: 2em;
    max-width: 560px;

    @include breakpoint(netbook) {
        margin-left: 2.66em;
    }

    .title {
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: .33em;
    }

    &.summary p {
        font-size: 16px;
    }
}

.social-sharing {
    margin-top: 1em;

    li {
        float: left;
        margin-left: 15px;

        &:first-child {
            margin-left: 0;
        }
    }

    .twitter {
        max-width: 90px;
    }
}

@include breakpoint(netbook) {

    .single-vacancies .vacancy-head,
    .vacancy-foot {
        padding: 1.25em;
        width: 100%;
        background-color: rgba($black, .05);
        @include box-sizing(border-box);
        @include border-radius(5px);
    }
}

.vacancy-head {

    &__summary {
        font-size: 18px;
        margin: 20px 0;
    }

    .entry-title {
        color: $dijon;
        text-transform: uppercase;
        font-size: 24px;
        line-height: 1.3;
        margin-bottom: .33em;
        text-align: center;
    }

    .apply-cta {
        display: none;

    }

    @include breakpoint(medium-tablet) {
        margin-bottom: 2em;

        .entry-title {
            text-align: left;
        }

        .major-info,
        .apply-cta {
            margin-bottom: 0;
            min-height: 227px;
        }

        .major-info {
            display: block;
            width: 58.5%;
            float: left;
        }

        .apply-cta {
            display: block;
            width: 37.5%;
            float: right;
            background: white;
            box-shadow: 0 1px 3px rgba(27, 27, 25, 0.3);

            p {
                font-size: 13px;
            }
        }
    }

    @include breakpoint(netbook) {


        .major-info {
            width: 63.5%;
        }

        .apply-cta {
            width: 33%;
        }

    }

    @include breakpoint(laptop) {

        .major-info {
            width: 66.5%;
        }

        .apply-cta {
            width: 30%;

            p {
                font-size: 14px;
            }
        }
    }
}

.vacancy-foot {
    max-width: 660px;


    .apply-cta {
        margin-bottom: 0;

        @include breakpoint(mobile-landscape) {
            text-align: left;

            .btn {
                padding-top: .65em;
                padding-bottom: .65em;
                margin-top: .4em;
            }

            .links {
                margin-top: .2em;

                li {
                    @include inline-block();
                    margin-left: 15px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        p {
            font-size: 16px;
        }
    }


}

.vacancy-info {
    font-size: 14px;

    dt,
    dd {
        @include inline-block();
    }

    dt {
        font-weight: bold;
        width: 80px;
    }

    a {
        color: $dijon;
        text-decoration: underline;

        &:hover {
            color: darken($dijon, 10%);
        }
    }
}

.apply-cta {
    text-align: center;

    .title {
        font-size: 24px;
        text-transform: uppercase;
        margin-bottom: .33em;
    }

    p {
        font-size: 14px;
    }

    .btn {
        width: 192px;
        margin: 1.5em 0 1.75em;
    }
}


.vacancy-achive {
    position: relative;
}


.vacancy-list-item {
    border-bottom: 2px solid $grey;
    padding-bottom: 1em;

    .title {
        display: block;
        margin: 1em 0 0;
    }

    .location {
        margin: .5em 0;
        display: block;
    }

    p {
        @include breakpoint(mobile-landscape) {
            width: 70%;
            //float:left;
        }
    }

    .btn {
        @include breakpoint(small-tablet) {
            float: right;
        }
    }
}

.error-message {
    padding: 1em 0;
}
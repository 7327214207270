// Mixins
	// A collection of mixins to help
	// ease development, including cross
	// browser. Also a space for project
	// specific mixins


// Media Queries
@mixin breakpoint($point) {
  @if $point == mobile-landscape {
    @media (min-width: 480px) { @content; }
  }
  @if $point == small-tablet {
    @media (min-width: 600px) { @content; }
  }
  @if $point == medium-tablet {
    @media (min-width: 700px) { @content; }
  }
  @else if $point == tablet-portrait {
    @media (min-width: 768px)  { @content; }
  }
  @else if $point == netbook {
    @media (min-width: 850px)  { @content; }
  }
  @else if $point == tablet-landscape {
    @media (min-width: 992px)  { @content; }
  }
  @else if $point == laptop {
    @media (min-width: 1024px)  { @content; }
  }
  @else if $point == desktop {
    @media (min-width: 1200px)  { @content; }
  }
}

// Webfont Icon
@mixin icon($library, $icon){
	font-family: $library;
	content: $icon;
}
.sectors {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__block {
    flex-basis: 23%;
    box-sizing: border-box;
    margin-bottom: 2%;

    @media only screen and (max-width: 1023px) {
      flex-basis: 32%;
    }

    @media only screen and (max-width: 767px) {
      flex-basis: 49%;
    }
  }

  a {
    background:$black;
    color: $sunflower;
    width: 100%;
    height: 200px;
    font-size: 26px;
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    text-align: center;
    transition: 0.3s;

    @media only screen and (max-width: 767px) {
      font-size: 20px;
    }

    &:hover, &:active, &:focus {
      background: $dijon;
      text-decoration: none;
      color: $white;
    }
  }
}
/*=========================================*\
    Form
    	| All code for generic form
    	| styling (theme wide) & plugin
    	| specific code
\*=========================================*/



/*=========================================*\
	Search Form
\*=========================================*/
.search-form {
    width: 100%;
    @include box-sizing(border-box);
    background-color: $black;
    padding: 5%;
    display: none;
    position: relative;
    -moz-background-clip: border;
    /* Firefox 3.6 */
    -webkit-background-clip: border;
    /* Safari 4? Chrome 6? */
    background-clip: border-box;
    /* Firefox 4, Safari 5, Opera 10, IE 9 */
    -moz-background-clip: padding;
    /* Firefox 3.6 */
    -webkit-background-clip: padding;
    /* Safari 4? Chrome 6? */
    background-clip: padding-box;
    /* Firefox 4, Safari 5, Opera 10, IE 9 */
    border: 4px solid rgba(0, 0, 0, 0.3);

    &.open {
        display: block;
    }

    li {
        margin-bottom: .75em;
    }

    label {
        color: $sunflower;
        font-weight: bold;
        font-size: 16px;
        display: block;
        margin-bottom: .4em;
    }

    .submit {
        max-width: 200px;
        display: block;
        margin: 1.25em auto 0;

        a {
            text-align: center;
            display: block;
            margin-top: 1em;
            font-size: 14px;
            font-weight: bold;
        }

        .btn {
            margin: .2em 0 0 .2em;

        }
    }

    @media only screen and (min-width: 1024px) {
        display: block;
        width: 90%;
        max-width: 1140px;
        margin: 4em auto 0;
        padding: 1.25em;
        @include border-radius(8px);

        .home & {
            margin: 0 auto;
            margin-bottom: 2em;
        }

        .inputs {
            float: left;
            width: 70%;

            li {
                float: left;
                width: 47.5%;
                margin-left: 5%;
                margin-bottom: 0;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .submit {
            float: right;
            width: 25%;
            margin-top: 1.55em;

            a {
                position: absolute;
                top: 0;
                right: 1.25em;
            }
        }
    }

    @include breakpoint(laptop) {

        margin: 2.75em auto 0;

        .inputs {
            width: 60%;
        }

        .submit {
            max-width: 100%;
            width: 37.5%;

            .btn {
                float: left;
                width: 47.5%;
            }

            a {
                float: right;
                width: 47.5%;
                margin-top: .7em;
                position: static;
                top: auto;
                right: auto;
            }
        }
    }

    @include breakpoint(desktop) {
        margin: 2.75em auto 0;

        .submit {
            max-width: 100%;
            width: 37.5%;

            .btn {
                float: left;
                width: 60%;
            }

            a {
                float: right;
                width: 37.5%;
                margin-top: .7em;
                position: static;
                top: auto;
                right: auto;
            }
        }
    }

}

#searchform {

    input,
    textarea {
        border: 0;
        box-shadow: none;
    }
}

.gfield {
    margin-top: 15px;
}

/*=========================================*\
	Input
\*=========================================*/
input,
textarea {
    background-color: $white;
    display: block;
    width: 100%;
    @include box-sizing(border-box);
    @include border-radius(5px);
    font-size: 14px;
    @include font-stack(Proxima);
    padding: .6em;
    outline: 0;
    margin: 0;
    box-shadow: inset 0 0 5px rgba(27, 27, 25, 0.3);
    border: 2px solid #fff;
}

/*=========================================*\
	Select
\*=========================================*/

/*=========================================*\
		CustomSelect
			| Specific styling for jQuery
			| replacement plugin
	\*=========================================*/
.custom-select-container {
    position: relative;
    width: 100%;
    display: block;
}

.js select {
    height: 2.7em;
    font-size: 14px;
    width: 100%;
    border: 0;
    @include transparent;
    line-height: 2;
    position: relative;
    top: -4px;
    left: 10px;
    z-index: 10;
}

.custom-select {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    @include border-radius(5px);
    padding: .2em;
    @include box-sizing(border-box);

    >span {
        display: block;
        padding: 0 25px 0 6px;
        color: $black;
        line-height: 2;
        font-size: 14px;
        overflow: hidden;

        &:before {
            @include icon('fontello', '\e75c');
            display: block;
            position: absolute;
            top: 0%;
            right: 0em;
            font-size: 16px;
            padding: .1em .75em .1em .66em;
            @include border-radius(0 4px 4px 0);
        }
    }

    span span {
        display: block;
        height: 2em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

    }
}

/*=========================================*\
	Checkbox
\*=========================================*/
.checkbox-item,
.radio-item {
    cursor: pointer;
    margin-top: .2em;
    display: block;

    input {
        float: left;
        width: 24px;
        height: 24px;
        margin-top: -1px;
        box-shadow: none;
        border: 0;
        margin-right: 2px;
    }

    span {
        float: left;
        color: $dijon;
        font-weight: bold;
        font-size: 14px;
    }
}

/*=========================================*\
	Radio
\*=========================================*/

/*=========================================*\
	Textarea
\*=========================================*/
textarea {
    background-color: $white;
    border: 0;
    display: block;
    width: 100%;
    @include box-sizing(border-box);
    @include border-radius(5px);
    font-size: 14px;
    @include font-stack(Proxima);
    padding: .6em;
    outline: 0;
    margin: 0;
}

/*=========================================*\
	Button
\*=========================================*/
.btn {
    border: 0;
    @include font-stack(Proxima);
    text-transform: uppercase;
    font-weight: bold;
    padding: .8em .01em;
    @include inline-block();
    @include box-sizing(border-box);
    @include border-radius(5px);
    width: 100%;
    max-width: 200px;
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    line-height: 1.1;
    transition: 0.3s;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        background: $black;
        color: $sunflower;
    }

    &.yellow {
        background-color: $sunflower;
        color: $black;

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
            background: $black;
            color: $sunflower;
        }
    }

    &.eggshell {
        background-color: $eggshell;
        color: $mustard;
        @include box-shadow(0 1px 3px rgba($black, .2));

        &:hover {
            background-color: darken($eggshell, 10%);
            color: $dijon;
        }
    }

    &.white {
        background-color: $white;
        @include box-shadow(0 1px 3px rgba($black, .2));

        &:hover {
            background-color: darken($white, 2%);
            color: $dijon;
        }
    }

    &.sml {
        font-size: 12px;
        line-height: 1.3;
        padding: .65em .3em;
        max-width: 150px;
    }
}

/*=========================================*\
	Contact Form
\*=========================================*/
.contact-form {
    .gform_wrapper {
        @extend .box-item;
        @extend .white;
    }

    .gform_title {
        text-transform: uppercase;
        margin-bottom: .66em;
    }

    li {
        margin-bottom: .75em;
    }

    label {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 13px;
    }

    input,
    .custom-select,
    textarea {
        @include box-shadow(inset 0 0 5px rgba($black, .3));
        border: 2px solid $white;

        &:hover,
        &:focus,
        &:active {
            background-color: darken($white, 2.5%);
            @include box-shadow(inset 0 0 5px rgba($black, .4));
        }
    }

    .gform_footer input {
        @extend .btn;
        @extend .yellow;
        @include box-shadow(inset 0 0 0 $black);
        margin: 2em auto 0;
        display: block;

        &:hover {
            background-color: darken($sunflower, 5%);
        }
    }

    li.gfield_error {

        input,
        .custom-select,
        textarea {
            @include box-shadow(inset 0 0 5px rgba($black, .1));
            border: 2px solid $sunflower;
            @include border-radius(5px 5px 0 5px);
        }

        .instruction.validation_message {
            display: none;
        }

        .validation_message {
            font-size: 13px;
            @include inline-block();
            float: right;
            padding: .3em;
            background-color: $sunflower;
            color: $black;
            font-weight: bold;
            @include border-radius(0 0 5px 5px);
        }
    }

}


.contact-form.candidates {
    margin-top: 30px;

    .gform_wrapper {}

    .gform_description {
        display: block;
        padding-bottom: 2em;
    }
}


.contact-form {

    ul {
        list-style-type: none !important;
        margin: 0 !important;
    }
}




/*=========================================*\
	    Gravity Forms
	    	| All code required for Gravity
	    	| Forms specific styling, 
	    	| including validation errors
	\*=========================================*/
.validation_error,
.instruction.validation_message,
.gform_edit_link,
.gform_validation_container {
    display: none;
}

.gform_button {
    cursor: pointer;
    background-color: $sunflower;
    margin: 1em 0;
    width: 30%;
    border: 0;
    box-shadow: none;
    transition: 0.3s;
    text-transform: uppercase;
    font-weight: 600;

    &:hover,
    &:focus,
    &:active {
        background: $black;
        color: $sunflower;
    }
}

.gform_body label span {
    margin-left: 4px;
}

li.gfield {
    @extend .cf;
}

li.gfield input,
li.gfield select,
li.gfield textarea {
    margin-bottom: 0;
}

.apply-form {
    text-align: left;
    position: relative;

    .button-hide {
        background-color: $sunflower;
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        outline: 0;
        cursor: pointer;
        border-radius: 5px;
    }
}

#applicationform1,
#applicationform {
    display: none;
}

.form-button {
    .gform_button {
        width: 70%;
    }
}

input[type=checkbox] {
    box-shadow: none;
    border: 0;
}

#input_2_9_1 {
    width: 20px;
    float: left;
    margin-top: 6px;
}
.featured-opportunities-slider {
    margin-bottom: 40px;

    .slick-slide {
        background: white;
        box-shadow: 0 1px 3px rgba(27, 27, 25, 0.3);
        border-radius: 3px;
        padding-bottom: 40px;
        position: relative;

        & > div {
            width: 100%;
        }
    }

    &__inner {
        box-sizing: border-box;
        width: 100%;
        padding: 20px;

        &:before {
            width: 100%;
            content: 'Vacancy';
            font-weight: bold;
            text-transform: uppercase;
            font-size: 14px;
            text-align: center;
            display: block;
            margin-bottom: 1em;
        }
    }

    p {
        font-size: 14px;
        margin: 20px 0;
    }

    &__meta {
        font-size: 16px !important;
        margin: 0 !important;
    }

    .major-title {
        color: #BC8D00;
        font-size: 22px;
        line-height: 30px;
    }

    .btn {
        position: absolute;
        bottom: 30px;
        right: 30px;
    }
}
// Colour Schema
	// Set all colour references here
	// as variables for use throughout
	// the theme

// Greyscale
$black: #1B1B19;
$grey: #EAEAEA;
$darkGrey: #BBBBBB;
$whiteSmoke: #efefef;
$white: #fff;

// Highlight
$eggshell: #FBF6E4;
$gold: #FAC810;
$sunflower: #E3B52A;
$mustard: #E1B347;
$dijon: #BC8D00;
$dark: #222;


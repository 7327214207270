/*================================================================*\
    Author: Jason Hobbs
    Copyright: Teabag Studios
    Contact: http://teabagstudios.com/ | hello@teabagstudios.com
\*================================================================*/

// Mixins & Variables
@import 'settings/_color.scss';
@import 'libs/_css3helper.scss';
@import 'settings/_mixins.scss';

// Reset
@import 'libs/_reset.scss';
@import 'settings/_normalise.scss';

// Typography
@import 'settings/_typography.scss';

// Animations
//@import 'animations/_animations.scss';

// Theme Globals
@import 'settings/_form.scss';
@import 'settings/_table.scss';
@import 'settings/_cms.scss';
@import 'settings/_text.scss';

// Libraries (use as needed)
//@import 'libs/dev/_debug.scss';
@import 'libs/dev/_animate.scss';
//@import 'libs/_flexslider.scss';
//@import 'libs/_socialite.scss';


// Elements
@import "elements/align.scss";

// Fonts

@import "fontawesome/fontawesome";
@import 'fontawesome/solid.scss';
@import 'fontawesome/regular.scss';
@import 'fontawesome/light.scss';
@import 'fontawesome/brands.scss';
@import 'fontawesome/v4-shims.scss';

// Components

@import "components/featured-opportunities-slider";
@import "components/slick";
@import "components/sector-slider";
@import "components/side-filter";
@import "components/news-single";
@import "components/news-main";
@import "components/sectors";
@import "components/landing-page";
@import "components/map";
@import "components/contact-details";
@import "components/page-content";

/*=========================================*\
    Structure
\*=========================================*/
html {
  background-color: $white;
  color: $black;
  @include font-stack(Proxima);
}

.wrap {
  width: 90%;
  max-width: 1140px;
  margin: 0 auto;
}

.content {
  padding: 2.5em 0;
  background-color: $whiteSmoke;
  position: relative;

  .wrap {
    position: relative;
    z-index: 5;
  }
}

@import 'structure/major/_head.scss';
@import 'structure/major/_sidebar.scss';
@import 'structure/major/_widget.scss';
@import 'structure/major/_foot.scss';

@import 'structure/major/_wp-page.scss';
@import 'structure/major/_wp-vacancy.scss';
@import 'structure/major/_wp-area.scss';

@import 'structure/supplementary/_wp-pagination.scss';


.box-item {
  padding: 1.2em 1.66em;
  @include box-sizing(border-box);
  @include border-radius(3px);
  margin-bottom: 1.25em;

  &.white {
    background-color: $white;
    @include box-shadow(0 1px 3px rgba($black, .3));
  }

  &.grey {
    background-color: $grey;
  }

  &.dark-grey {
    background-color: $darkGrey;
    color: $black;

    a {
      color: $white;
    }
  }

  &.eggshell {
    background-color: $eggshell;
    border: 1px solid $white;
    @include box-shadow(0 1px 3px rgba($black, .15));
  }

  &.flush-img {
    padding: 0;

    .lower {
      padding: 1em 1.66em 1em 1.66em;
      @include box-sizing(border-box);
      text-align: center;
    }

    &.white .title a {
      color: $dijon;

      &:hover {
        color: darken($dijon, 5%);
      }
    }
  }

  &--bottom {
    padding: 2em!important;
    margin-top: 30px;

    h1 {
      font-size: 22px!important;
    }
  }
}

.white-background {
  background: white;
}

.latest-vacancies,
.latest-news {
  margin-bottom: .5em;

  .main-title {
    font-size: 22px;
    margin-bottom: .25em;

    @include breakpoint(small-tablet){
      display: none;
    }
  }
  .tweets {
    min-height : 264px;
  }
}

.latest-news {

  &__grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__block {
    width: 48%;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.news-item,
.vacancy-item {
  text-align: center;


  .btn {
    margin: 1.5em auto 0;
    display: block;
  }

  @include breakpoint(mobile-landscape){
    text-align: left;
  }

  @include breakpoint(small-tablet){
    text-align: center;
  }

  @include breakpoint(netbook){
    text-align: left;
  }
}


.vacancy-item.centred{
  text-align:center;
}

.news-item {
  position: relative;
  //min-height: 220px;

  @include breakpoint(mobile-landscape){
    min-height: auto;
    padding-bottom: 4em;
  }

  @include breakpoint(small-tablet){
    //min-height: 100px;
    padding-bottom: 1.66em;
  }

  @include breakpoint(tablet-portrait){
    min-height: auto;
    padding-bottom: 4em;
  }

  @include breakpoint(tablet-landscape){
    min-height: auto;
    padding-bottom: 1.66em;

    &:before {
      content: 'News';
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
      text-align: center;
      display: block;
      margin-bottom: 1em;
    }
  }

  .entry-title a {
    color: $dijon;

    &:hover {
      color: darken($dijon, 5%);
      text-decoration: none;
    }
  }

  .entry-summary {
    margin-top: .66em;
    @include breakpoint(small-tablet){
      min-height: 70px;
    }
    @include breakpoint(tablet-portrait){
      min-height: auto;
    }
    @include breakpoint(tablet-landscape){
      min-height: 63px;
    }
  }

  .btn {
    margin-top: 3em;

    @include breakpoint(mobile-landscape){
      margin: 0;
      position: absolute;
      bottom: 2em;
      right: 2em;
    }

    @include breakpoint(small-tablet){
      text-align: center;
      position: static;
      bottom: auto;
      right: auto;
      margin: 2.5em auto 0;
    }

    @include breakpoint(tablet-portrait){
      margin: 0;
      position: absolute;
      bottom: 2em;
      right: 2em;
    }

    @include breakpoint(tablet-landscape){
      text-align: center;
      position: static;
      bottom: auto;
      right: auto;
      margin: 2.5em auto 0;
    }
  }
}

.vacancy-item {
  position: relative;

  @include breakpoint(mobile-landscape){
    min-height: auto;
    padding-bottom: 4em;
  }

  @include breakpoint(small-tablet){
    //min-height: 100px;
    padding-bottom: 1.66em;
  }

  @include breakpoint(tablet-portrait){
    min-height: auto;
    padding-bottom: 4em;
  }

  @include breakpoint(tablet-landscape){
    min-height: auto;
    padding-bottom: 1.66em;

    &:before {
      content: 'Vacancy';
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
      text-align: center;
      display: block;
      margin-bottom: 1em;
    }
  }

  .vacancy-head {
    margin-bottom: .5em;
    line-height: 1.4;
  }

  .major-title {
    @include breakpoint(desktop){
      min-height: 41px;
    }
    a {
      color: $dijon;

      &:hover {
        color: darken($dijon, 5%);
        text-decoration: none;
      }
    }
  }

  .location {
    font-weight: normal;
    font-size: 14px;
  }

  .cms-area {
    margin-top: .66em;
    @include breakpoint(small-tablet){
      min-height: 110px;
    }
    @include breakpoint(tablet-portrait){
      min-height: auto;
    }
    @include breakpoint(tablet-landscape){
      min-height: 105px;
    }
  }

  .btn {
    margin-top: 3em;

    @include breakpoint(mobile-landscape){
      margin: 0;
      position: absolute;
      bottom: 2em;
      right: 2em;
    }

    @include breakpoint(small-tablet){
      text-align: center;
      position: static;
      bottom: auto;
      right: auto;
      margin: 2.5em auto 0;
    }

    @include breakpoint(tablet-portrait){
      margin: 0;
      position: absolute;
      bottom: 2em;
      right: 2em;
    }

    @include breakpoint(tablet-landscape){
      text-align: center;
      position: static;
      bottom: auto;
      right: auto;
      margin: 2.5em auto 0;
    }
  }
}

.archive-title{
  display : block;
  width : 100%;
  border-bottom : 2px solid $grey;
  text-transform : uppercase;
  margin-top : 1em;
  padding-bottom : 1em;
}

.cta-item {
  .title {
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: .66em;
  }

  .cms-area p {
    font-size: 16px;
  }

  .read-more {
    font-size: 14px;
    display: block;
    margin-top: 1.5em;
  }
}

.tweets {
  position: relative;

  .time {
    display: none;
  }

  &.box-item {
    padding-top: 4em;
  }

  &:before {
    @include icon('fontello','\f309');
    text-align: center;
    font-size: 30px;
    color: $mustard;
    position: absolute;
    top: .33em;
    left: 50%;
    margin-left: -15px;
  }

  a {
    color: $dijon;
  }

  .tweet {
    padding: 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    &.last {
      border: 0;
    }

    &-image {
      width: 100%;
      height: 160px;
      margin-bottom: 12px;
      background-size: cover;
      border-radius: 8px;
    }
  }
}

@include breakpoint(small-tablet){
  .split-block {
    width: 47.5%;
  }

  .split-left {
    float: left;
  }

  .split-right {
    float: right;
  }
}

@include breakpoint(netbook){
  .split-block {
    width: 100%;
    float: none;
  }
}

@include breakpoint(tablet-landscape){
  .major-item {
    .split-block {
      width: 47.5%;
    }

    .split-left {
      float: left;
    }

    .split-right {
      float: right;
    }
  }

  .minor-item .box-item {
    margin-bottom: 1.66em;
  }
}

.triple-stack .stack-item {
  @include breakpoint(small-tablet){
    width: 47.5%;
    margin-right: 5%;
    float: left;
    margin-bottom: 2em;

    &.mult-2 {
      margin-right: 0;
    }
  }

  @include breakpoint(netbook){
    width: 30%;
    margin-right: 5%;

    &.mult-2 {
      margin-right: 5%;
    }

    &.mult-3 {
      margin-right: 0;
    }
  }
}

.page-title.arrowed {
  font-size: 28px;
  text-transform: uppercase;
  line-height: 1.3;
  position: relative;
  margin-bottom: .5em;

}

.home .page-title.arrowed {
  padding-top : .5em;

  @include breakpoint(mobile-landscape){
    font-size: 32px;
    &:before {
      top: 5px;
    }
  }

  @include breakpoint(small-tablet){
    font-size: 40px;
    line-height: 1.2;
    &:before {
      top: 7px;
      width: 29px;
      height: 78px;
    }
  }
}

.split-item {
  margin-top: 1em;

  &:first-child {
    margin-top: 0;
  }
}

@include breakpoint(tablet-portrait){
  .uneven-split.contact-split {

    .split-item {
      margin-top: 0;
    }

    .major-item,
    &.switched .minor-item {
      float: left;
    }

    .minor-item,
    &.switched .major-item {
      float: right;
    }

    .major-item {
      width: 62.5%;
    }

    .minor-item {
      width: 32.5%;
    }
  }
}

@include breakpoint(netbook){
  .uneven-split.contact-split {
    .major-item {
      width: 55%;
    }

    .minor-item {
      width: 40%;
    }
  }
}

@include breakpoint(tablet-landscape){
  .uneven-split.contact-split {
    .major-item {
      width: 50%;
    }

    .minor-item {
      width: 45%;
    }
  }
}

@include breakpoint(netbook){
  .uneven-split {

    .split-item {
      margin-top: 0;
    }

    .major-item,
    &.switched .minor-item {
      float: left;
    }

    .minor-item,
    &.switched .major-item {
      float: right;
    }

    &.split-2-1 {
      .major-item {
        width: 62.5%;
      }

      .minor-item {
        width: 32.5%;
      }
    }

    &.split-3-1 {
      .major-item {
        width: 70%;
      }

      .minor-item {
        width: 25%;
      }
    }

    &.split-3-1_5 {
      .major-item {
        width: 65%;
      }

      .minor-item {
        width: 30%;
      }
    }
  }
}

.list {
  li a{
    background : url(../images/arrows.png);
    background-repeat : no-repeat;
    background-position : 0px 3px;
    padding-left : 1em;
  }

  li a:hover {
    background-position : 0px -16px;
    color : $black;
  }
}


.padding {
  padding: 40px 0;
}
/*=========================================*\
    Supplementary
\*=========================================*/

@media print {
  @import 'libs/_print.scss';
}
